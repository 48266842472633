import { NextPage } from 'next';
import React, { useEffect } from 'react';
import { Router } from '~i18n';

const Index: NextPage = () => {
  useEffect(() => {
    Router.push('/my-bookings');
  }, []);
  return <div></div>;
};

export default Index;
